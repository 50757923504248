@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    @if $env == development {
        src: local('Material Icons'),
        local('MaterialIcons-Regular'),
            url( '/static/components/material-design-icons/iconfont/MaterialIcons-Regular.woff2' ) format('woff2'),
            url( '/static/components/material-design-icons/iconfont/MaterialIcons-Regular.woff' ) format('woff');
    }
    @else {
        src: local('Material Icons'),
        local('MaterialIcons-Regular'),
            url( 'https://web-cdn.freedompop.com/libs/material-design-icons/3.0.1/iconfont/MaterialIcons-Regular.woff2' ) format('woff2'),
            url( 'https://web-cdn.freedompop.com/libs/material-design-icons/3.0.1/iconfont/MaterialIcons-Regular.woff' ) format('woff');
    }
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
